import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { Flex, Box, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { BsTools } from 'react-icons/bs';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper width={960} mdxType="Wrapper">
      <hr></hr>
  <Heading icon={<BsTools mdxType="BsTools" />} mdxType="Heading">Service</Heading>
      <p>{`  Professioneller Service gehört seit jeher zu den wesentlichen Tugenden unseres Hauses. Wir reparieren Ihre
Armbanduhren, Ihre Großuhren, führen Goldschmiedereparaturen aus und kaufen Ihr Altgold an.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "altgoldankauf"
      }}>{`Altgoldankauf`}</h2>
      <p>{`  Wir als Juwelier, haben uns auf den Ankauf von Altgold sowie den Verkauf von Anlagegold spezialisiert. Wir bieten
Ihnen eine breite Palette von Dienstleistungen im Zusammenhang mit dem Kauf und Verkauf von Edelmetallen wie Gold,
Silber und Platin an. Dabei stehen Fairness, Qualität und Kundenzufriedenheit stets im Vordergrund.`}</p>
      <p>{`  Beim Altgold-Handel können Sie ihren ungenutzten Gold- und Silberschmuck, Goldmünzen, Goldbarren und Zahngold an uns
verkaufen. Auch bieten wir weitere Services wie die Veredelung von Schmuck oder die Aufarbeitung von Altgold an.
Hierbei arbeiten wir mit unseren erfahrenen Goldschmieden zusammen, um Ihre individuellen Kundenwünsche umzusetzen.`}</p>
      <p>{`  Im Bereich des Anlagegold-Handels verkaufen wir in der Regel Edelmetallbarren und -münzen in verschiedenen Größen und
Gewichten. Diese können entweder direkt im Ladengeschäft erworben oder online bestellt werden. Wir legen hier
besonderen Wert auf die Qualität der Produkte und arbeitet nur mit renommierten Herstellern zusammen. Diese Produkte
werden oft als sichere und stabile Anlageform betrachtet und sind daher bei Investoren und Sammlern sehr beliebt.`}</p>
      <p>{`  Darüber hinaus bieten wir auch Bewertungen von Edelmetallstücken und Schätzungen des aktuellen Marktwerts an. Wir
bieten Beratungsdienste bei der Auswahl von Anlageprodukten und unterstützen und informieren unseren Kunden über den
aktuellen Stand des Edelmetallmarkts.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "ohrlochstechen-ab-5-jahren"
      }}>{`Ohrlochstechen ab 5 Jahren`}</h2>
      <p>{`  Herzlichen Glückwunsch zu Ihrer Entscheidung, sich Ohrlöcher stechen zu lassen! Als Juwelier haben wir jahrelange
Erfahrung im Ohrlochstechen und legen großen Wert auf die Sicherheit und Hygiene bei diesem Prozess.`}</p>
      <p>{`  Unser geschultes Personal wird Ihnen vorab alle notwendigen Informationen geben und auf Ihre Fragen eingehen. Wir
verwenden ausschließlich sterile Einwegnadeln und sorgen dafür, dass alle Instrumente und Arbeitsbereiche gründlich
desinfiziert sind.`}</p>
      <p>{`  Unser Sortiment an Ohrringen umfasst eine breite Palette von Designs und Materialien, von medizinischen
Erstohrsteckern, von klassisch bis trendy, sodass Sie die perfekte Wahl für Ihren persönlichen Stil treffen können.
Wir werden Ihnen auch Anweisungen für die Pflege Ihrer neuen Ohrlöcher geben, um eine schnelle Heilung und eine lange
Lebensdauer Ihrer Ohrringe zu gewährleisten.`}</p>
      <p>{`  Wir freuen uns darauf, Ihnen bei Ihrem neuen Look zu helfen und stehen Ihnen gerne für weitere Fragen und
Terminvereinbarungen zur Verfügung.`}</p>
      <p>{`  Bitte vereinbaren sie dafür telefonisch oder persönlich einen Termin mit uns und denken Sie daran, dass wir erst bei
Kindern ab 5 Jahren stechen und dass bei Kindern und Jugendlichen, die das 18. Lebensjahr noch nicht vollendet haben,
eine erziehungsberechtigte Person dabei anwesend sein muss.`}</p>
    </Wrapper>
    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h2 {...{
        "id": "leistungen"
      }}>{`Leistungen`}</h2>
  <Flex flexWrap={'wrap'} justifyContent='space-between' mdxType="Flex">
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Uhren Service</h3>
      <List mdxType="List">
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Mechanische Uhren / Handaufzug</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Quarzarmbanduhren</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Automatikuhren</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Batteriewechsel</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Komplette Revisionen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Glasersatz</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Leder-, Metall-, Kuststoffuhrarmbänder</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Bandanpassung</ListItem>
      </List>
    </Box>
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Goldschmiede Service</h3>
      <List mdxType="List">
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Goldschmiedereparaturen aller Art</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Kette und Ösen zulöten, Verschlüsse ersetzen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Ringgrößen ändern</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Perlenketten neu fassen oder knoten</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Trauringumarbeitung</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Neuanfertigungen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Schmuck reinigen und aufarbeiten</ListItem>
      </List>
    </Box>
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Großuhren</h3>
      <List mdxType="List">
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Heim – und Tischuhren, Jahresuhren</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Standuhren</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Regulatoren</ListItem>
      </List>
    </Box>
    <Box width={['100%', '100%', '50%', '50%']} p={'6'} mdxType="Box">
      <h3>Altgoldankauf</h3>
      <List mdxType="List">
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen
          an:</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Zahngold, Goldnuggets, Altgold aus Schmuckstücken und
          Münzen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Wir prüfen fachkundig und unkompliziert und vergüten nach
          aktuellen Edelmetallkursen</ListItem>
        <ListItem mdxType="ListItem"><ListIcon as={CheckIcon} color='brand.500' mdxType="ListIcon" />Altgoldankauf ist Vertrauenssache</ListItem>
      </List>
    </Box>
  </Flex>
  <CallToAction mdxType="CallToAction" />
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      